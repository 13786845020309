<template>
  <div class="container">
    <div class="user-nav">
      <Search
        :isClass="false"
        :isSearch="false"
        :isBtn="false"
        :termlist="termlist"
        ref="search"
        @handleChange="handleChange"
      />
    </div>
    <div class="main">
      <div class="table-box">
        <h4>学业水平</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>写实记录</th>
            <th>平时成绩</th>
            <th>期中成绩</th>
            <th>期末成绩</th>
            <th>学科比赛</th>
            <th>课堂表现</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{ academicForm.realyCount }}</td>
            <td>{{ academicForm.comExamCount }}</td>
            <td>{{ academicForm.midExamCount }}</td>
            <td>{{ academicForm.finExamCount }}</td>
            <td>{{ academicForm.subMatchCount }}</td>
            <td>{{ academicForm.showCount }}</td>
            <td>{{ academicForm.sumCount }}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{ academicForm.realyScore }}</td>
            <td>{{ academicForm.comExamScore }}</td>
            <td>{{ academicForm.midExamScore }}</td>
            <td>{{ academicForm.finExamScore }}</td>
            <td>{{ academicForm.subMatchScore }}</td>
            <td>{{ academicForm.showScore }}</td>
            <td>{{ academicForm.sumScore }}</td>
          </tr>
        </table>
      </div>
      <div class="more">
        <h4>相关记录</h4>
        <el-row
          type="flex"
          justify="space-between"
          class="more-tit"
          style="margin-bottom: "
		  v-if="showTestRes.usual"
        >
          <el-col :span="12">
            <h5>平时成绩</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex" style="margin: 0">
              <span>测试次数：{{ indexResult.commonCount }}</span>
              <span>综素成绩：{{ indexResult.commonZSScore }}</span>
              <span>
                <el-button type="primary" plain @click="handleMore"
                  >查看详情</el-button
                >
              </span>
            </div>
          </el-col>
        </el-row>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
		  v-if="showTestRes.middle"
        >
          <el-col :span="12">
            <h5>期中成绩</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex">
              <span>总成绩：{{ indexResult.middleScore }}</span>
              <span>综素成绩：{{ indexResult.middleZSScore }}</span>
            </div>
          </el-col>
        </el-row>
        <el-table
          :data="midlist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
		  v-if="showTestRes.middle"
        >
          <el-table-column label="学科" fixed>
            <template>原始成绩</template>
          </el-table-column>
          <el-table-column v-for="(item,index) in midTitle" :key="index" :prop="item.code" :label="item.name"> </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
		  v-if="showTestRes.final"
        >
          <el-col :span="12">
            <h5>期末成绩</h5>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <div class="more-flex">
              <span>总成绩：{{ indexResult.finalScore }}</span>
              <span>综素成绩：{{ indexResult.finalZSScore }}</span>
            </div>
          </el-col>
        </el-row>
        <el-table
          :data="finallist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
		  v-if="showTestRes.final"
        >
          <el-table-column label="学科" fixed>
            <template>原始成绩</template>
          </el-table-column>
          <el-table-column v-for="(item,index) in finalTitle" :key="index" :prop="item.code" :label="item.name"> </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>学科比赛</h5>
          </el-col>
          <el-col :span="12" style="text-align: right" v-if="curRole == 'student'">
			  <el-button @click="uploadMyAc" type="primary" plain
				><i class="el-icon-circle-plus"></i>上传学科比赛</el-button>
		  </el-col>
        </el-row>
        <el-table
          :data="competionlist"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="matchName" label="赛事名称"> </el-table-column>
          <el-table-column prop="indexRuleLevel" label="赛事级别">
          </el-table-column>
          <el-table-column prop="matchTime" label="比赛时间"> </el-table-column>
		  <el-table-column prop="_auditStatus" label="审核状态" width="120">
		  </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailRace(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-row
          type="flex"
          justify="space-between"
          class="more-tit more-tittop"
        >
          <el-col :span="12">
            <h5>课堂表现</h5>
          </el-col>
          <el-col :span="12" style="text-align: right"> </el-col>
        </el-row>
        <el-table
          :data="evaluationList"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column prop="evalItem" label="评价项目" width="150">
          </el-table-column>
          <el-table-column prop="termName" label="学期" width="200">
          </el-table-column>
          <el-table-column prop="evalType" label="评价方式"> </el-table-column>
          <el-table-column prop="selfEval" label="学生自评"> </el-table-column>
          <el-table-column prop="otherEval" label="学生互评"> </el-table-column>
          <el-table-column prop="teacherEval" label="教师评价">
          </el-table-column>
          <el-table-column prop="evalStatus" label="状态"> </el-table-column>
          <el-table-column prop="evalResult" label="评价结果">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.selfEval == '未完成' && roleKey == 'student'"
                @click="handleEvaluate(scope.row)"
                type="text"
                size="small"
                >去评价</el-button
              >
              <span v-else> —— </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 查看比赛详情 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <el-form-item label="比赛名称：">
          <span class="scontent">{{ form.matchName }}</span>
        </el-form-item>
        <el-form-item label="比赛级别：">
          <span class="scontent">{{ form.indexRuleLevel }}</span>
        </el-form-item>
		<el-form-item label="获奖等级：">
		  <span class="scontent">{{ form.resultLevel }}</span>
		</el-form-item>
        <el-form-item label="比赛时间：">
          <span class="scontent">{{ form.matchTime }}</span>
        </el-form-item>
        <el-form-item
          label="举办单位："
          style="border-bottom: 1px dashed #e1e3e9"
        >
          <span class="scontent">{{ form.awardedUnit }}</span>
        </el-form-item>
        <el-form-item label="所在学期：">
          <span class="scontent">{{ form.termName }}</span>
        </el-form-item>
        <!-- <el-form-item label="所在学校：">
          <span class="scontent">{{ form.schoolName }}</span>
        </el-form-item> -->
       <el-form-item label="佐证材料：" v-if="form._showImgList">
			<el-image
			  v-for="(item, index) in form._imgFileList"
				style="width: 100px; height: 100px;"
				:preview-src-list="form._imgFileList"		
			  :key="index"
			  :src="item"
			></el-image>
		   </el-form-item>
		<el-form-item label="佐证材料：" v-if="form._showOtherFileList">
				<a
				v-for="(item, idx) in form._otherFileList"
				:key="item"
				target="_blank"
				:download="'附件' + idx"
				:href="item">附件{{ idx + 1 }} </a>
		</el-form-item>
        <el-form-item label="备注说明：">
          <span class="scontent">{{ form.bz }}</span>
        </el-form-item>
		<el-form-item label="审核状态：">
		    <span class="scontent">{{ aStatus(form.auditStatus) }}</span>
		</el-form-item>
		<el-form-item label="不通过原因：" v-if="form.auditStatus == 2">
		    <span class="scontent">{{ form.causeDes }}</span>
		</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
		<el-button type="primary" @click="reupload" v-if="form.auditStatus == 0 || form.auditStatus == 2">重新编辑上传</el-button>
      </span>
    </el-dialog>

    <!-- 平时成绩 -->
    <el-dialog :title="title" :visible.sync="openMore" width="75%" center>
      <el-table
        :data="uausllist"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column v-for="(item,index) in uauslTitle" :key="index" :prop="item.code" :label="item.name" min-width="180"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openMore = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 去评价 -->
    <el-dialog :title="title" :visible.sync="openEval" width="900px" center>
      <p class="eval">
        欢迎您参加课堂表现评价，您需要先评价系统随机为您选取的若干名同班同学，在完成他们的评价后可以进行自我评价。
      </p>
      <el-form :model="forms" ref="forms" :rules="rules">
        <el-table
          :data="forms.evalData"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="100">
            <template slot-scope="scope">
              {{
                scope.$index + 1 == forms.evalData.length
                  ? "自评"
                  : scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            property="userName"
            label="姓名"
            width="120"
          ></el-table-column>
          <el-table-column
            property="sex"
            label="性别"
            width="100"
          ></el-table-column>
          <el-table-column label="表现情况">
            <template slot-scope="scope">
              <el-form-item>
                <el-radio-group v-model="scope.row.evalScore">
                  <el-radio label="10">A 优秀</el-radio>
                  <el-radio label="8">B 良好</el-radio>
                  <el-radio label="6">C 一般</el-radio>
                  <el-radio label="4">D 稍差</el-radio>
                </el-radio-group>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <p style="color: red" v-if="isTips">请对{{ tips }}进行评价</p>
        <el-button type="primary" @click="submitEval">提 交</el-button>
      </span>
    </el-dialog>
	<!-- 个人学科比赛 -->
	<el-dialog title="上传学科比赛" :visible.sync="dialog.addMyAc" width="600px" center>
		<el-form :model="addMyAcForm" :rules="addMyAcFormRules" ref="addMyAcForm" label-width="100px">
			<el-form-item label="学生姓名：" prop="userName">
				<span>{{ addMyAcForm.userName }}</span>
			</el-form-item>
			<el-form-item label="学籍号：" prop="studentCode">
				<span>{{ addMyAcForm.studentCode }}</span>
			</el-form-item>
			<el-form-item label="比赛名称：" prop="matchName">
			  <el-input style="width: 300px;" placeholder="请输入比赛名称" v-model="addMyAcForm.matchName"></el-input>
			</el-form-item>
			<el-form-item label="比赛级别：" prop="indexRuleLevel">
			  <el-select
			    style="width: 300px;"
			    v-model="addMyAcForm.indexRuleLevel"
			    placeholder="请选择比赛级别"
			  >
			    <el-option
			      v-for="(item, index) in constData.acLvlList"
			      :key="index"
			      :label="item.name"
			      :value="item.code"
			    ></el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="获奖等级：" prop="resultLevel">
				<el-input style="width: 300px;" placeholder="请输入获奖等级" v-model="addMyAcForm.resultLevel"></el-input>
			</el-form-item>
			<el-form-item label="比赛时间：" prop="matchTime">
				<el-date-picker
				  v-model="addMyAcForm.matchTime"
				  type="date"
				  format="yyyy-MM-dd"
				  value-format="yyyy-MM-dd"
				  placeholder="请选择比赛时间"
				  style="width: 300px;"
				  :picker-options="ltToday"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="举办单位：" prop="awardedUnit">
				<el-input style="width: 300px;" placeholder="请输入举办单位" v-model="addMyAcForm.awardedUnit"></el-input>
			</el-form-item>
			<el-form-item label="所属学期：">
			  <span>{{ addMyAcForm.termName }}</span>
			</el-form-item>
			<el-form-item label="佐证材料：" prop="file">
				<el-upload
					:action="upload.action"
					:accept="upload.accept"
					:limit="upload.limit"
					:multiple="upload.multiple"
					:file-list="upload.list"
					:on-change="uploadChange"
					:on-remove="uploadRemove"
					:auto-upload="upload.auto"
				  >
					<el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
				  </el-upload>
			</el-form-item>
			<el-form-item label="备注说明：" prop="bz">
				<el-input style="width: 300px;" type="textarea" v-model="addMyAcForm.bz" placeholder="情况说明,选填"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="closeDialog('addMyAc')">取 消</el-button>
		  <el-button type="primary" @click="confirmUploadMyAc">确 定</el-button>
		</div>
	</el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  academicByUserId,
  getProjectRaceById,
  getProjectRaceDetail,
  getUsualResultById,
  getMidResult,
  getFinalResult,
  getIndexResult,
  getEvaluateById,
  addProjectRace,
  showTestTp
} from "@/api/teacher/quality/Academic";
import { getStudentEval, saveStudentEval } from "@/api/student/myquality/xueye";
import { getDic } from "@/api/public/search"
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
			curRole: this.$store.getters.roles[0].roleKey,
      termName: "",
      userId: "",
      title: "",
      open: false,
      openMore: false,
      openEval: false,
      tableData: [],
      gridData: [],
      form: {},
      forms: {
        evalData: [],
      },
      rules: {
        expression: [
          { required: true, message: "请对Ta进行评价", trigger: "change" },
        ],
      },
      termlist: [],
      academicForm: {},
      uausllist: [],
      uauslTitle: [],
      midlist: [],
      midTitle: [],
      finallist: [],
      finalTitle: [],
      competionlist: [],
      indexResult: {
        middleScore: 0,
        middleZSScore: 0,
        finalScore: 0,
        finalZSScore: 0,
      },
	  showTestRes: {
		usual: false,
		middle: false,
		final: false
	  },
      evaluationList: [],
      evalItemList: [],
      unEvalList: [],
      tips: "",
      isTips: false,
      roleKey: "",
	  addMyAcForm: {
		userName: this.$store.getters.name,
		studentCode: this.$store.getters.loginName,
		termName: '',
		matchName: '',
		schoolName: '',
		matchTime: '',
		indexRuleLevel: '',
		awardedUnit: '',
		resultLevel: '',
		id: '',
		bz: ''
	  },
	  upload: {
		action: '',
		accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
		limit: 3,
		multiple: false,
		list: [],
		auto: false
	  },
	  constData: {
	  	acLvlList: []
	  },
	  dialog: {
	  	addMyAc: false
	  },
	  addMyAcFormRules: {
		matchName: [
		  { required: true, message: "请输入比赛名称", trigger: "blur" },
		],
		indexRuleLevel: [
		  { required: true, message: "请选择比赛级别", trigger: "change" },
		],
		matchTime: [{
			required: true, message: "请选择比赛时间", trigger: "blur"
		}],
		awardedUnit: [
		  { required: true, message: "请输入举办单位", trigger: "blur" },
		]
	  },
    }
  },
  components: { Search },
  created() {
    if (this.$store.getters.roles.length > 0) {
      this.roleKey = this.$store.getters.roles[0].roleKey;
    }
    this.$store.dispatch("GetTermList").then((res) => {
      this.termlist = res.data;
      this.termName = this.$store.getters.queryForm.termName;
	  this.addMyAcForm.termName = this.$store.getters.queryForm.termName;
      this.userId = this.$store.getters.userId;
      this.$refs.search.init(this.termName);
      this.getFirst();
    });
    // console.log(this.$store.getters.userInfo);
	this.getAcLvlDic()
  },
  methods: {
	// 审核状态
	aStatus(st) {
		return ['未审核', '审核通过', '审核驳回'][st]
	},
	// 荣誉级别字典
	getAcLvlDic() {
		getDic({
			type: "matchLevel"
		}).then(res => {
			this.constData.acLvlList = res.data
		})
	},
	// 打开上传个人荣誉
	uploadMyAc() {
		this.addMyAcForm.matchName = ''
		this.addMyAcForm.matchTime = ''
		this.addMyAcForm.indexRuleLevel = ''
		this.addMyAcForm.awardedUnit = ''
		this.addMyAcForm.resultLevel = ''
		this.addMyAcForm.bz = ''
		this.addMyAcForm.id = ''
		this.upload.list = []
		this.openDialog('addMyAc')
	},
	// 打开弹窗
	openDialog(nm) {
		this.dialog[nm] = true
	},
	// 关闭弹窗
	closeDialog(nm) {
		this.dialog[nm] = false
	},
	// 确认上传个人荣誉
	confirmUploadMyAc() {
		this.$refs.addMyAcForm.validate(vld => {
			if (vld) {
				let fd = new FormData()
				fd.append('userName', this.addMyAcForm.userName)
				fd.append('studentCode', this.addMyAcForm.studentCode)
				fd.append('matchName', this.addMyAcForm.matchName)
				fd.append('matchTime', this.addMyAcForm.matchTime)
				fd.append('indexRuleLevel', this.addMyAcForm.indexRuleLevel)
				fd.append('awardedUnit', this.addMyAcForm.awardedUnit)
				fd.append('resultLevel', this.addMyAcForm.resultLevel)
				if (this.addMyAcForm.id) {
					fd.append('id', this.addMyAcForm.id)
				}
				if (this.upload.list.length > 0) {
					this.upload.list.forEach(v => {
						fd.append('file', v)
					})
				}
				fd.append('bz', this.addMyAcForm.bz)
				addProjectRace(fd).then(res => {
					this.$message.success(`个人学科比赛${ res.message }`)
					this.closeDialog('addMyAc')
					this.getCopetion()
				})
			} else {
				return false
			}
		})
	},
	// 材料上传
	uploadChange(file, fileList) {
		let rawTp = file.raw.type
		// pdf, excel表格, word文档, 图, 纯文本
		let allowTp = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif', 'image/png', 'image/jpeg', 'text/plain']
		if (allowTp.indexOf(rawTp) == -1) {
			this.$message.error("材料仅支持pdf、excel表格、word、图片与纯文本格式！")
			fileList.pop()
		} else {
			this.upload.list.push(file.raw)
			this.$message.success("上传成功")
		}
	},
	// 材料删除
	uploadRemove(file, fileList) {
		this.upload.list = fileList
	},
	// 审核驳回重新编辑上传
	reupload() {
		this.open = false
		this.addMyAcForm = Object.assign(this.addMyAcForm, this.form)
		// 找到级别
		let lvl = ''
		this.constData.acLvlList.forEach(v => {
			if (v.name == this.form.indexRuleLevel) {
				lvl = v.code
			}
		})
		this.addMyAcForm.indexRuleLevel = `${lvl}`
		this.upload.list = []
		this.openDialog('addMyAc')
	},
	// 处理附件
	processAth(key) {
		let temp = key.split(",")
		let imgFileList = []
		let otherFileList = []
		// 材料按文件后缀名分流
		temp.forEach(v => {
			if (this.fileNotImg(v)) {
				otherFileList.push(this.imgSrc + v)
			} else {
				imgFileList.push(this.imgSrc + v)
			}
		})
		this.form._imgFileList = imgFileList
		this.form._otherFileList = otherFileList
		this.form._showImgList = imgFileList.length > 0
		this.form._showOtherFileList = otherFileList.length > 0
	},
	handleChange(data) {
	  this.termName = data
	  this.addMyAcForm.termName = data
	  this.getMorality()
	  this.getHonorList()
	  this.getWeijiList()
	},
    handleChange(val) {
      this.termName = val;
      this.getFirst();
    },
    getFirst() {
      this.getAcademic();
	  // 判断那些测试需要显示
	  showTestTp({
		  termName: this.termName,
		  userId: this.userId
	  }).then(res => {
		  let d = res.data
		  this.showTestRes.usual = d.includes('1')
		  this.showTestRes.middle = d.includes('2')
		  this.showTestRes.final = d.includes('3')
		  // 平时1，期中2，期末3
		  if (d.includes('1')) {
			  this.getUauslResult()
		  }
		  if (d.includes('2')) {
		  	this.getMidResult();  
		  }
		  if (d.includes('3')) {
		  	this.getFinalResult();		  
		  }
	  })
      this.getCopetion();
      // this.getResult();
      this.getEvaluate();
    },
    getAcademic() {
      academicByUserId({
        userId: this.userId,
        termName: this.termName,
      }).then((res) => {
        // console.log(res);
        this.academicForm = res.data;
      });
    },
    getUauslResult() {
      getUsualResultById({
        userId: this.userId,
        termName: this.termName,
        examType: '1'
      }).then((res) => {
        this.indexResult.commonCount = res.data.examNum
        this.indexResult.commonZSScore = res.data.indexScore
        this.uausllist = res.data.content
        this.uauslTitle = res.data.title
      });
    },
    getMidResult() {
      getMidResult({
        userId: this.userId,
        termName: this.termName,
        examType: '2'
      }).then((res) => {
        if(res.data) {
          this.indexResult.middleScore = res.data.sumScore
          this.indexResult.middleZSScore = res.data.indexScore
          this.midlist = res.data.content
          this.midTitle = res.data.title
        }
      });
    },
    getFinalResult() {
      getFinalResult({
        userId: this.userId,
        termName: this.termName,
        examType: '3'
      }).then((res) => {
        if(res.data) {
          this.indexResult.finalScore = res.data.sumScore
          this.indexResult.finalZSScore = res.data.indexScore
          this.finallist = res.data.content
          this.finalTitle = res.data.title
        }
      });
    },
    getCopetion() {
      getProjectRaceById({
        userId: this.userId,
        termName: this.termName,
        pageSize: 100,
        pageNum: 1,
      }).then((res) => {
        // console.log(res);
		res.data.forEach(v => {
			v._auditStatus = this.aStatus(v.auditStatus)
		})
        this.competionlist = res.data;
      });
    },
    getResult() {
      getIndexResult({
        userId: this.userId,
        termName: this.termName,
      }).then((res) => {
        // console.log(res);
        this.indexResult = res.data;
      });
    },
    getEvaluate() {
      this.evaluationList = [];
      getEvaluateById({
        userId: this.userId,
        termName: this.termName,
      }).then((res) => {
        // console.log(res);
        this.evaluationList.push(res.data);
      });
    },
    handleMore() {
      this.title = "平时成绩";
      this.openMore = true;
    },
    handleDetailRace(row) {
      this.title = "学科比赛";
	  this.addMyAcForm.id = row.id
      this.open = true;
      getProjectRaceDetail({ matchId: row.id }).then((res) => {
        // console.log(res);
        this.form = res.data;
        if(this.form.proofFile) {
          this.processAth(this.form.proofFile)
        }
      });
    },
    handleEvaluate(row) {
      getStudentEval({
        userId: this.userId,
        termName: this.termName,
      }).then((res) => {
        // console.log(res);
        this.forms.evalData = res.data;
        this.title = "课堂表现评价";
        this.openEval = true;
      });
    },
    submitEval() {
      this.evalItemList = [];
      this.unEvalList = [];
      this.tips = "";
      this.forms.evalData.forEach((item) => {
        if (item.evalScore != null) {
          this.evalItemList.push({
            userId: item.userId,
            evalScore: item.evalScore,
          });
        } else {
          this.unEvalList.push(item.userName);
        }
      });
      if (this.unEvalList.length == 0) {
        this.isTips = false;
        saveStudentEval({
          userId: this.userId,
          termName: this.termName,
          evalList: this.evalItemList,
        }).then((res) => {
          // console.log(res);
          this.$message.success("提交成功");
          this.getFirst();
          this.openEval = false;
        });
      } else {
        this.isTips = true;
        this.unEvalList.forEach((item) => {
          this.tips += item + "、";
        });
        this.tips = this.tips.substr(0, this.tips.length - 1);
      }
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .user-nav {
    padding: 10px 12px;
  }
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 12.5%;
          }
        }
      }
    }
  }
  .eval {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #666;
  }
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>